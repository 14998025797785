import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className='App'>
      <div
        style={{
          background: 'linear-gradient(180deg, #072D90 0%, #07298B 100%)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          padding: '100px 0',
        }}
      >
        <div className='klaviyo-form-U33mhi' style={{ margin: '0 auto' }}></div>
      </div>
    </div>
  );
}

export default App;
